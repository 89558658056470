export const META_CONTENTS = Object.freeze({
  // Public Pages
  ['home']: {
    title: `Your Trusted Self Storage Partner`,
    description: `ShipAndStorage offers smart self storage solutions with worldwide shipping services via FedEx and DHL. Discover our range of units, convenient booking, and exceptional service!`,
    keywords: `smart storage, worldwide shipping , FedEx, DHL, storage services , shipping services , secure storage, storage solutions , international shipping `
  },
  ['how_ship_and_storage_works']: {
    title: `How Ship and Storage Works for College Students`,
    description: `Learn how Ship and Storage works for college students. Convenient and secure storage solutions for all your needs.`,
    keywords: `how it works, college storage, student storage, secure storage, cheap storage`
  },
  ['college_storage_services']: {
    title: `College Storage Services for Dorms and Students`,
    description: `College storage services for students during summer or semester breaks. Convenient and secure storage for dorm room items and personal belongings.`,
    keywords: `college storage services, student storage solutions, dorm room storage, ShipAndStorage college options, secure storage for students, summer break storage`
  },
  ['drop_off_storage_locations']: {
    title: `Convenient Drop Off Storage Locations Near You`,
    description: `Find nearby drop-off storage locations to securely store your belongings. Flexible storage options available for short or long-term needs. Easy drop-off, secure storage!`,
    keywords: `drop off storage, storage locations near me, convenient storage drop off, secure storage locations, temporary storage, local storage options, drop off storage services`
  },
  ['pickup_and_storage']: {
    title: `Hassle-Free Pickup and Storage Services for Your Items`,
    description: `Enjoy easy pickup and storage services for your belongings. Our reliable team collects, stores, and returns your items whenever you need. Book today`,
    keywords: `pickup and storage, storage pickup service, hassle-free storage, easy storage options, storage with pickup, secure storage solutions, pickup and delivery storage`
  },
  ['pickup_from_storage']: {
    title: `Fast and Reliable Pickup for Your Stored Items`,
    description: `Schedule a fast and reliable pickup for your stored items at your convenience. Secure solutions for easy item retrieval when you need them.`,
    keywords: `pickup storage, stored items, secure storage, quick retrieval, item retrieval services`
  },
  ['how_storage_services_works']: {
    title: `How Storage Services Work for Your Items`,
    description: `Learn how our storage services work for keeping your items safe. Secure and flexible storage options for all types of belongings.`,
    keywords: `how storage works, secure storage, business storage, personal storage`
  },
  ['how_storage_solutions_work']: {
    title: `How Storage Solutions Work – Simple & Secure Storage Guide`,
    description: `Discover how our storage solutions work! A step-by-step guide to safely store and retrieve your items. Flexible options for all your storage needs.`,
    keywords: `how storage solutions work, storage process, easy storage guide, storage solutions, secure storage services, flexible storage options, step-by-step storage`
  },
  ['domestic_shipping_services']: {
    title: `Domestic Shipping Services for Boxes and Luggage`,
    description: `Domestic shipping services for moving boxes, luggage, and more. Affordable and reliable with convenient pickup and drop-off options.`,
    keywords: `domestic shipping services, local shipping solutions, box shipping, luggage shipping, secure shipping, ShipAndStorage domestic logistics`
  },
  ['international_shipping_services']: {
    title: `International Shipping Services for Students and Travelers`,
    description: `International shipping services for luggage, gear, and personal items. Safe, reliable, and affordable shipping worldwide.`,
    keywords: `international shipping services, global shipping solutions, student shipping, luggage shipping, secure international shipping, ShipAndStorage worldwide logistics`
  },
  ['shipping_to_storage']: {
    title: `Shipping to Storage for All Your Belongings`,
    description: `Enjoy safe and flexible shipping to storage services with ShipAndStorage. Convenient, affordable, and secure options for your belongings.`,
    keywords: `storage delivery, shipping solutions, gear transport, box storage shipping, luggage storage shipping, secure shipping, storage logistics.`
  },
  ['luggage_shipping']: {
    title: `Luggage Shipping Anytime, Anywhere!`,
    description: `Ship your moving boxes, luggage, or sport gear directly to our storage facility. Convenient and secure shipping options available.`,
    keywords: `baggage transport, luggage delivery, international luggage, secure shipping, travel shipping, domestic luggage, shipping services.`
  },
  ['moving_box_shipping']: {
    title: `Moving Box Shipping to Anywhere`,
    description: `Affordable moving box shipping for both domestic and international moves. Safe and convenient with flexible pickup or drop-off options.`,
    keywords: `moving box delivery, secure packaging, box transport, domestic moving, international moving, shipping services, relocation shipping.`
  },
  ['snowboard_shipping']: {
    title: `Snowboard Shipping Services to Resorts and Storage`,
    description: `Ship your snowboard to your favorite resort or storage facility with our safe and affordable shipping services.`,
    keywords: `snowboard delivery, secure transport, snowboard shipping services, international shipping, domestic shipping, sports gear shipping, snowboard transport.`
  },
  ['ski_gear_shipping']: {
    title: `Ski Gear Shipping Services for Domestic and International`,
    description: `Ship your ski gear domestically or internationally with our secure shipping services. Convenient options for pickup or drop-off.`,
    keywords: `ski equipment shipping, secure transport, ski gear delivery, international shipping, domestic shipping, sports gear shipping, ski shipping services.`
  },
  ['college_student_shipping']: {
    title: 'College Student Shipping Solutions Secure and Fast',
    description:
      'College student shipping services for dorm room items and personal belongings. Safe, affordable, and convenient shipping solutions.',
    keywords:
      'college student shipping, student luggage shipping, dorm room shipping, ShipAndStorage for students, secure student package delivery'
  },
  ['summer_camp_shipping']: {
    title: 'Summer Camp Shipping Services for Gear and Luggage',
    description:
      'Summer camp shipping services for camp gear, luggage, and more. Convenient and affordable shipping to any camp location.',
    keywords:
      'summer camp shipping, camp luggage delivery, ShipAndStorage camp solutions, secure summer camp transport, camp gear shipping'
  },
  ['how_to_attach_shipping_labels']: {
    title: 'How to Attach Shipping Labels Correctly',
    description:
      'Learn how to attach shipping labels correctly to ensure safe delivery. Follow our guide for proper labeling techniques.',
    keywords: 'attach shipping labels, shipping labels, shipping guide, secure shipping, how-to-attach-shipping-labels'
  },
  ['use_correct_packing_material']: {
    title: 'Use the Correct Packing Material for Shipping',
    description:
      'Use the correct packing material to protect your items during shipping. Follow our guide for choosing the right materials.',
    keywords: 'packing materials, shipping guide, secure packing, shipping boxes, use-correct-packing-material'
  },
  ['cost_saving_tips_on_shipping']: {
    title: 'Cost-Saving Tips On Shipping Boxes and Luggage',
    description:
      'Get expert tips on how to save money when shipping your boxes and luggage. Follow our advice for affordable and secure shipping.',
    keywords: 'cost-saving tips, shipping tips, secure shipping, affordable shipping, cost-saving-tips-on-shipping'
  },
  ['shipping_weight_and_sizes']: {
    title: 'Understand Shipping Weight and Size Limits',
    description:
      'Understand the weight and size limits for shipping boxes, luggage, and more. Ensure compliance with our shipping guidelines.',
    keywords: 'weight limits, size limits, shipping guidelines, secure shipping, shipping-weight-and-sizes'
  },
  ['what_we_ship']: {
    title: 'What We Ship: Items Allowed for Shipping',
    description:
      'Find out what items we ship, including luggage, boxes, and sport gear. Safe and secure shipping for all approved items.',
    keywords: 'what we ship, approved shipping items, secure shipping, luggage shipping, what-we-ship'
  },
  ['what_we_store']: {
    title: 'What We Store: Items Allowed for Storage',
    description:
      'Learn what items we store safely in our facilities. Flexible storage options for approved personal and business items.',
    keywords: 'what we store, approved storage items, what-we-store, secure storage, business storage'
  },
  ['shipping_insurance']: {
    title: 'Shipping Insurance To Protect Your Shipments',
    description:
      'Learn about shipping insurance options to protect your valuable items. Ensure safe delivery with reliable coverage.',
    keywords: 'shipping insurance, valuable items shipping, secure shipping, insurance, shipping Insurance'
  },
  ['track_your_shipping']: {
    title: 'Track Your Shipping Status in Real-Time',
    description:
      'Track your shipment in real-time with our easy tracking tool. Get instant updates on the status and location of your items.',
    keywords: 'track your shipping, shipment tracking, track-your-shipping, real-time tracking, shipping status'
  },
  ['office_equipment_storage']: {
    title: 'Office Equipment Storage for Businesses of All Sizes',
    description:
      'Store office equipment safely in our secure facilities. Flexible storage options and easy retrieval for businesses of all sizes.',
    keywords:
      'office equipment storage, secure storage, business storage, equipment storage, business equipment storage solutions'
  },
  ['product_sample_storage']: {
    title: 'Secure Product Sample Storage for Your Business',
    description:
      'Keep your product samples safe with our secure storage options. Ship to multiple locations as needed.',
    keywords: 'product sample storage, secure storage, business storage, flexible storage, warehouse storage'
  },
  ['trade_show_storage']: {
    title: 'Trade Show Storage Solutions for Business Materials',
    description:
      'Store your trade show materials securely between events. Flexible storage and shipment to any location.',
    keywords: 'trade show storage, business storage, secure storage, event storage, Inventory management'
  },
  ['sport_gear_storage']: {
    title: 'Sport Gear Storage Solutions for Golfers and Skiers',
    description:
      'Store your sport gear like golf clubs, skis, and snowboards with us. Save time and space at home by shipping your gear to secure storage.',
    keywords: 'sport gear storage, golf club storage, ski gear storage, snowboard storage, sports storage'
  },
  ['dorm_room_storage']: {
    title: 'Dorm Room Storage Solutions for College Students',
    description:
      'Store your dorm room items securely during summer or semester breaks. Easy storage and retrieval for college students.',
    keywords: 'dorm room storage, secure storage, student storage, college storage'
  },
  ['college_storage_fee']: {
    title: 'College Storage Fees - Affordable and Transparent Pricing',
    description:
      'Explore our affordable college storage fees with flexible pricing options for students. Store your dorm room items safely and securely.',
    keywords: 'college storage fees, affordable storage, flexible pricing, student storage'
  },
  ['gear_storage_fee']: {
    title: 'Affordable Sport Gear Storage Fees for All Equipment',
    description:
      'Explore our affordable sport gear storage fees with flexible pricing options for golfers, skiers, and snowboarders. Secure storage guaranteed.',
    keywords: 'sport gear storage fees, affordable storage, flexible pricing, secure storage, safe storage '
  },
  ['storage_and_shipping_tips']: {
    title: 'ATop Storage and Shipping Tips for Sport Gear',
    description:
      'Get expert tips for storing and shipping your sport gear safely. Follow our guidelines for a smooth experience.',
    keywords:
      'packaging techniques, storage solutions, shipping methods, organizing equipment, gear protection, product storage, shipping strategies.'
  },
  ['storage_weight_and_size_limit']: {
    title: 'Know the Weight and Size Limits for Storage',
    description:
      'Know the weight and size limits for items stored in our secure facility. Ensure your items meet our guidelines for safe storage.',
    keywords:
      'Storage size limits, Storage weight limits, storage guidelines, secure storage, storage facility regulations'
  },
  ['business_storage_fee']: {
    title: 'Affordable Business Storage Fees for All Your Needs',
    description:
      'Learn about our affordable business storage fees. Flexible pricing options for storing your business equipment, documents, and more.',
    keywords:
      'business storage fees, affordable storage, flexible storage, business storage pricing, affordable business storage'
  },
  ['document_storage_and_archiving']: {
    title: 'Document Storage and Archiving Solutions for Businesses',
    description:
      'Keep your business documents and archives safe with our secure storage solutions. Convenient retrieval and shipping options available.',
    keywords: 'document storage, archiving solutions, secure storage, business archives, secure document storage'
  },
  ['drop_off_residential_storage']: {
    title: 'Drop-off Residential Storage Solutions for Your Needs',
    description:
      'Drop off your residential items at our secure storage facility with ease. Convenient solutions for short or long-term storage needs, available today.',
    keywords: 'drop-off storage, storage facility, secure storage, convenient storage, on-demand storage'
  },
  ['seasonal_item_storage']: {
    title: 'Secure Seasonal Item Storage for Businesses',
    description:
      'Store your seasonal business items safely with our flexible storage options. Available for short-term or long-term needs.',
    keywords: 'seasonal item storage, business storage, secure storage, flexible storage, flexible business storage'
  },
  ['drop_off_college_storage']: {
    title: 'Convenient Drop-off Solutions for College Storage',
    description:
      'Drop off your dorm room items at our secure storage facility. Convenient solutions for college students during breaks.',
    keywords: 'drop-off storage, college storage, secure storage, student storage, affordable storage'
  },
  ['drop_off_sports_gear_storage']: {
    title: 'Convenient Drop-off Solutions for Sport Gear Storage',
    description:
      'Drop off your sport gear at our secure storage facility. Convenient options for storing equipment when not in use.',
    keywords: 'drop-off storage, sport gear storage, secure storage, flexible storage, ship for storage'
  },
  ['residential_storage_fee']: {
    title: 'Residential Storage Fees - Affordable and Transparent Pricing',
    description:
      'Learn about residential storage fees with transparent pricing and no hidden costs. Affordable storage solutions for your home or business needs.',
    keywords: 'storage fees, affordable storage, residential storage, pricing options, self-storage units'
  },
  ['prohibited_items_to_ship']: {
    title: 'Prohibited Items You Cannot Ship with Us',
    description:
      'Check the list of prohibited items you cannot ship with us. Ensure compliance with our rules for safe and secure shipping.',
    keywords: 'prohibited items, shipping guidelines, secure shipping, sport gear shipping'
  },
  ['prohibited_items_to_store']: {
    title: 'Prohibited Items for Storage | ShipAndStorage',
    description:
      'Learn which items cannot be stored at ShipAndStorage, including hazardous materials, perishables, and restricted goods. Keep your storage safe and compliant.',
    keywords:
      'prohibited storage items, items not allowed in storage, restricted items for storage, what not to store, storage facility prohibited items, banned items in storage units, unsafe items for storage'
  },
  ['luggage_storage_for_college']: {
    title: 'Convenient & Secure College Luggage Storage',
    description:
      'Easily store your luggage with our secure college storage solutions. Affordable, flexible, and perfect for students moving or traveling.',
    keywords:
      'college luggage storage, student storage, dorm storage, secure student storage, moving storage, temporary luggage storage, university storage'
  },
  ['luggage_storage_for_residential']: {
    title: 'Secure & Affordable Residential Luggage Storage',
    description:
      'Store your luggage safely with our affordable residential storage solutions. Secure, flexible, and hassle-free options for short or long-term storage.',
    keywords:
      'luggage storage, residential storage, home luggage storage, secure storage, temporary storage, long-term luggage storage, household storage'
  },
  ['residential_storage']: {
    title: `Smart Residential Storage Solutions`,
    description: `ShipAndStorage offers smart residential storage solutions for various needs.  Find your perfect residential storage solution where convenience meets excellence.`,
    keywords: `Residential storage, emergency storage, smart storage, secure storage , renovation storage , worldwide shipping, convenient storage, storage solutions, valet storage, military deployment storage, household storage , affordable storage, temporary storage.`
  },
  ['residential_garage_storage_solution']: {
    title: `Residential Garage Storage Solutions | ShipAndStorage`,
    description: `ShipAndStorage offers secure and flexible residential garage storage solutions. Store your belongings safely with convenient pickup and delivery options.`,
    keywords: `residential storage, garage storage, storage solutions, home storage, secure storage, ShipAndStorage, storage services`
  },
  ['business_storage_solution']: {
    title: `Your Premier Business Storage Solution`,
    description: `Find perfect business storage solution with SAS. Our storage units including warehouse & office space. Enjoy our affordable & flexible business storage today!`,
    keywords: `Storage units, commercial storage, retail storage, office document storage, pharmaceutical storage, storage solutions, trade show storage, business storage, secure storage, worldwide shipping , smart storage, inventory storage, e-commerce storage, document storage, affordable storage.`
  },
  ['moving_storage']: {
    title: `Hassle-Free Moving Storage Solutions `,
    description: `Discover convenient, flexible, and secure storage options with ShipAndStorage. Book now for your moving and storage needs!`,
    keywords: `Valet storage, door-to-door delivery, affordable storage, FedEx shipping, luggage storage, box storage, hassle-free storage, free storage, moving storage, secure storage, moving solutions, international shipping, storage solutions, simple pricing, transparent pricing, pack and ship, convenient storage.`
  },
  ['luggage_and_box_shipping_services']: {
    title: `Easy Luggage & Box Shipping Anywhere`,
    description: `ShipAndStorage simplifies luggage shipping. Order online and ship your luggage or boxes anywhere. Enjoy real-time tracking, free insurance, and 24/7 support.`,
    keywords: `Shipping tips, prohibited items real-time tracking, box shipping , labeling  free insurance , 24/7 support , delivery instructions , international shipping, convenient shipping, hotel pickup, shipment protection, secure shipping , luggage shipping.`
  },
  ['book_storage_facility']: {
    title: `Secure Self Storage Facility - Get A Quote`,
    description: `Get a quote for secure and convenient self storage facility solutions at ShipAndStorage..`,
    keywords: `self storage, storage facility, book now, get a quote, secure storage, convenient storage`
  },
  ['business_storage_solutions']: {
    title: `Secure Self Storage Facility - Get A Quote`,
    description: `Get a quote for secure and convenient self storage facility solutions at ShipAndStorage..`,
    keywords: `self storage, storage facility, book now, get a quote, secure storage, convenient storage`
  },
  ['residential_storage_solutions']: {
    title: `Smart Residential Storage Solutions | ShipAndStorage`,
    description: `ShipAndStorage offers smart residential storage solutions for various needs. Find your perfect residential storage solution where convenience meets excellence.`,
    keywords: `Residential storage, emergency storage, smart storage, secure storage , renovation storage , worldwide shipping, convenient storage, storage solutions, valet storage, military deployment storage, household storage , affordable storage, temporary storage.`
  },
  ['moving_storage_solutions']: {
    title: `Hassle-Free Moving Storage Solutions | ShipAndStorage`,
    description: `Discover convenient, flexible, and secure storage options with ShipAndStorage. Book now for your moving and storage needs!`,
    keywords: `Valet storage, door-to-door delivery, affordable storage, FedEx shipping, luggage storage, box storage, hassle-free storage, free storage, moving storage, secure storage, moving solutions, international shipping, storage solutions, simple pricing, transparent pricing, pack and ship, convenient storage.`
  },
  ['luggage_storage_services']: {
    title: `Secure & Affordable Luggage Storage Services`,
    description: `Need a reliable luggage storage facility? Keep luggage safe with our secure bag storage solutions. Find luggage storage near me with easy pickup & delivery!`,
    keywords: `luggage storage services, luggage storage near me, luggage storage facility, bag storage, keep luggage, secure storage, short-term storage`
  },
  ['shipping_partners']: {
    title: `Secure & Affordable Luggage Storage Services`,
    description: `Need a reliable luggage storage facility? Keep luggage safe with our secure bag storage solutions. Find luggage storage near me with easy pickup & delivery!`,
    keywords: `luggage storage services, luggage storage near me, luggage storage facility, bag storage, keep luggage, secure storage, short-term storage`
  },
  ['partner_login']: {
    title: `Partner Login for Storage and Shipping Partners`,
    description: `Partner login for storage and shipping partners. Manage your account and access tools for a seamless partnership experience.`,
    keywords: `partner login, manage services, storage partner, shipping partner`
  },
  ['news_and_press']: {
    title: `News and Press: Latest Updates About Our Services`,
    description: `Stay updated with the latest news and press releases about our company. Learn more about our services and upcoming initiatives.`,
    keywords: `news and press, company updates, press releases, service updates`
  },
  ['register']: {
    title: `Register with ShipAndStorage`,
    description: `Sign up for a ShipAndStorage account to enjoy our storage and shipping services.`,
    keywords: `register, sign up, create account, membership`
  },
  ['login']: {
    title: `Log in to our platform | Secure Access`,
    description: `Log in securely to your ShipAndStorage account to manage your storage and shipping needs. Visit shipandstorage.com/login.`,
    keywords: `login, secure access, account management, storage management, shipping `
  },
  ['change_password']: {
    title: `Change Password`,
    description: `Update your ShipAndStorage account password easily and securely on the Change Password page.`,
    keywords: `change password, update password, account security, password reset`
  },
  ['reset_password']: {
    title: `Reset Password`,
    description: `Easily reset your password for your ShipAndStorage account.`,
    keywords: `reset password, forgot password, account access`
  },
  ['order_packing_material']: {
    title: `Quality Packing Materials for Your Shipping Needs`,
    description: `Find a wide range of quality packing materials for your shipping needs at ShipAndStorage. From boxes to bubble wrap, we have you covered.`,
    keywords: `packing materials, shipping supplies, packaging materials, boxes, bubble wrap, tape, packing peanuts, shipping boxes, packing boxes, packaging supplies, shipping essentials, packing materials store`
  },
  ['about']: {
    title: `Smart Shipping & Storage Solution`,
    description: `Learn about ShipAndStorage's journey and commitment to providing convenient storage and shipping solutions.`,
    keywords: `about us, company history, our story, mission, values, storage solutions, shipping services, commitment, convenience, ShipAndStorage`
  },
  ['contact']: {
    title: `Get in Touch | Contact Information`,
    description: `Reach out to ShipAndStorage for any inquiries or assistance regarding storage and shipping services. Visit shipandstorage.com/contact-us.`,
    keywords: `contact us, get in touch, customer support, inquiries, assistance, storage services, shipping services, ShipAndStorage`
  },
  ['luggage_storage']: {
    title: `Innovative Luggage Storage & Shipping Solutions`,
    description: `Discover secure, affordable luggage storage and shipping solutions with ShipAndStorage. Flexible options for travelers, no size restrictions, and 24/7 support.`,
    keywords: `Luggage Storage, Box Storage, Ski Storage, Golf Club Storage, Snowboard Storage, Shipping & Storage Questions, Shipping Tips, What Can't Ship, Secure Storage, Affordable Luggage Storage, Flexible Storage Options, NYC Luggage Storage, Travel Storage Solutions, Convenient Luggage Storage, Luggage Storage Near Me, Smart Storage Solutions, ShipAndStorage, Luggage Shipping, Travel Tips, Vacation Storage, Hassle-Free Storage, Safe Luggage Storage, Luggage Storage NYC, Travel Convenience.`
  },
  ['box_storage']: {
    title: `Convenient Box Storage Solutions`,
    description: `Store your boxes securely with ShipAndStorage. Affordable rates, no size restrictions, and flexible pickup/drop-off options. Store without renting extra space.`,
    keywords: `box storage, storage solutions, secure storage, convenient storage, ShipAndStorage`
  },
  ['box_storage_near_me']: {
    title: `Box Storage Near Me | Affordable & Secure Local Solutions`,
    description: `Find box storage near you! Discover affordable, secure, and convenient storage options for your belongings.`,
    keywords: `Box storage near me, Local box storage, Affordable box storage, Secure box storage, Storage units near me, Box storage facilities near me, Nearby box storage options`
  },
  ['moving_box_storage']: {
    title: `Secure and Convenient Moving Box Storage Options`,
    description: `ShipAndStorage offers affordable moving box storage options. Pay only for what you need while relocating, decluttering, or needing temporary storage.`,
    keywords: `moving box storage, secure storage, storage services, convenient storage, temporary storage`
  },
  ['golf_club_storage']: {
    title: `Reliable Golf Club Storage Solutions`,
    description: `Keep your golf clubs secure with ShipAndStorage. Affordable, flexible storage options for golf enthusiasts. Perfect for travel and seasonal storage.`,
    keywords: `golf club storage, golf equipment storage solutions, golf club storage unit`
  },
  ['golf_club_storage_near_me']: {
    title: `Secure & Affordable Golf Club Storage Near Me`,
    description: `Need a safe place for your golf clubs? Our golf club storage facility offers secure, flexible, and affordable storage solutions with pickup & delivery options.`,
    keywords: `golf club storage near me, golf bag storage, golf equipment storage, secure golf storage, store golf clubs, golf gear storage, long-term golf storage`
  },
  ['ski_storage']: {
    title: `Secure Ski Storage Solutions`,
    description: `Discover convenient and secure ski storage solutions at ShipAndStorage. Reserve your storage space today!`,
    keywords: `ski boot storage, ski gear storage, ski storage tips, snow ski storage, ski storage system, ski equipment storage`
  },
  ['ski_gear_storage']: {
    title: `Secure Ski Gear Storage for Seasonal Use`,
    description: `Keep your ski gear secure during off-seasons. Our flexible storage options ensure your equipment is safe and ready for use when needed.`,
    keywords: `ski gear storage, secure storage, seasonal storage, sport gear storage, golf club storage`
  },
  ['ski_gear_storage_solution']: {
    title: `Ski Gear Storage Solutions - Safe & Convenient Storage`,
    description: `Store your ski gear safely and conveniently with ShipAndStorage. Affordable, secure, and hassle-free storage solutions for your winter equipment.`,
    keywords: `Ski gear storage, winter sports storage, ski equipment, ski storage solutions, secure ski storage, affordable ski storage, ski gear safety`
  },
  ['snowboard_storage']: {
    title: `Secure and Affordable Snowboard Storage Solutions`,
    description: `Store your snowboard with ShipAndStorage. Affordable and flexible storage options for seasonal and travel needs. Safe and secure storage solutions.`,
    keywords: `Snowboard Storage, Secure Snowboard Storage, Seasonal Storage, Flexible Storage, Affordable Storage, Safe Snowboard Storage, Snowboard Equipment Storage, Storage Solutions, Convenient Snowboard Storage, Smart Storage Solutions, ShipAndStorage, Travel Storage, Winter Sports Storage, Snowboard Gear Storage, Snowboard Equipment, Easy Snowboard Storage, Storage Near Me, Storage Services, Safe Storage Options, Snowboarding Storage, Affordable Snowboard Storage, Secure Storage Facilities.`
  },
  ['snowboard_storage_near_me']: {
    title: `Secure & Affordable Snowboard Storage Near Me`,
    description: `Looking for a safe place to store your snowboard? Our storage facility offers secure, climate-controlled, and affordable snowboard storage solutions.`,
    keywords: `snowboard storage near me, snowboard storage facility, store snowboard, snowboard gear storage, winter sports storage, climate-controlled storage, board storage`
  },
  ['luggage_box_storage']: {
    title: `Best Cheapest Storage Facilities Near Me`,
    description: `Luggage shipping services, Best luggage shipping services, International luggage shipping services, Luggage shipping service, Best international luggage shipping service, Cheapest luggage shipping service, Box shipping services, Box shipping services`,
    keywords: `Did you think of shipping directly from smart storage? No matter, where you are? we can ship your luggage and boxes anytime, anywhere! Get Cheapest Shipping.`
  },
  ['privacy']: {
    title: `Privacy Policy`,
    description: `Learn how ShipAndStorage protects your privacy with our privacy policy.`,
    keywords: `privacy, policy, data protection, personal information`
  },
  ['terms']: {
    title: `Terms and Conditions`,
    description: `Read and agree to ShipAndStorage's terms & conditions before using our services.`,
    keywords: `terms, conditions, agreement, legal`
  },
  ['prohibited_items']: {
    title: `Prohibited and Restricted Items`,
    description: `Learn about restricted goods like aerosols, alcohol, dangerous goods etc to ensure your shipments comply with regulations. Prohibited items are not allowed.`,
    keywords: `What Can't Ship, Restricted Items, Prohibited Items, Shipping Restrictions, Safe Shipping, ShipAndStorage, Shipping Compliance, Shipping Regulations, Storage Solutions, Shipping Solutions, Smart Shipping, Shipping Guidance, Storage Services, Shipping Services, Customer Support, Shipping Help, Safe Storage, Shipping Safety, Restricted Shipments, Prohibited Shipments, Safe Shipping Practices.`
  },
  ['shipping_tips']: {
    title: `Top Shipping Tips for Efficient and Safe Delivery`,
    description: `Optimize your shipping process with ShipAndStorage's expert tips. Learn about packaging, labeling, and cost-saving strategies to ensure efficient delivery.`,
    keywords: `Shipping Tips, Packaging Tips, Labeling Tips, Shipping Advice, Safe Shipping, Efficient Shipping, ShipAndStorage, Shipping Solutions, Storage Solutions, Smart Shipping Tips, Shipping Guides, Packing Advice, Shipping Services, Storage Services, Shipping Help, Storage Help, Customer Support, Shipping Recommendations, Safe Packing, Shipping Efficiency, Storage Tips, Shipping Assistance.`
  },
  ['general_faq']: {
    title: `Shipping & Storage Frequently Asked Questions`,
    description: `Find answers to your shipping and storage questions with ShipAndStorage. Comprehensive FAQ to help you with all your storage and shipping needs.`,
    keywords: `Shipping & Storage Questions, Shipping FAQ, Storage FAQ, Shipping Answers, Storage Answers, ShipAndStorage, Storage Solutions, Shipping Solutions, Common Questions, Shipping Tips, Storage Tips, Storage Services, Shipping Services, Customer Support, Storage Help, Shipping Help, Frequently Asked Questions, Storage Guidance, Shipping Guidance, Help Center, Smart Storage Solutions, Shipping & Storage Help.`
  },
  ['shipping_weight']: {
    title: `Shipping Weight and Size Guidelines`,
    description: `Understand shipping weight and size guidelines with ShipAndStorage. Ensure your items meet requirements for smooth delivery.`,
    keywords: `shipping weight, shipping size, shipping guidelines, ShipAndStorage, weight requirements, size requirements, shipping regulations, delivery guidelines, package weight, package size, shipping dimensions, weight limits, size limits, shipping instructions, shipping compliance, smooth delivery, shipping solutions, packaging requirements, shipping tips`
  },
  ['luggage_tracking']: {
    title: `Real-Time Luggage Tracking Services`,
    description: `Track your luggage in real-time with ShipAndStorage. Enjoy peace of mind with our reliable luggage tracking solutions.`,
    keywords: `luggage tracking, real-time tracking, luggage services, ShipAndStorage, tracking solutions, secure tracking, travel tracking, luggage monitoring, track luggage, real-time luggage updates, travel peace of mind, luggage security, tracking system, luggage safety, shipment tracking, travel solutions, reliable tracking`
  },
  ['luggage_drop_off_location']: {
    title: `Convenient Luggage Drop Off Locations`,
    description: `Find secure and convenient luggage drop-off locations with ShipAndStorage. Hassle-free storage solutions for travelers.`,
    keywords: `luggage drop off, secure luggage storage, convenient drop off, travel storage, ShipAndStorage, luggage services, storage locations, luggage solutions, easy drop off, luggage storage facilities, travel convenience, drop off points, secure storage, storage options, luggage safety, travel storage solutions, luggage services`
  },
  ['url_list']: {
    title: `Sitemap | Easy Access to All Pages and Services`,
    description: `Explore the comprehensive sitemap for ShipAndStorage.com, providing easy access to all our services, including shipping, storage solutions, and logistics support. Navigate through our pages to find the right solutions for your storage and shipping needs.`,
    keywords: `ShipAndStorage sitemap, shipping services, storage solutions, logistics support, page directory, shipping and storage sitemap, logistics sitemap, website navigation, sitemap for ShipAndStorage.com`
  },

  ['unauthorized']: {
    title: `401 unauthorized`,
    description: ``,
    keywords: ``
  },
  ['not_found']: {
    title: `Ops! 404 page not found`,
    description: ``,
    keywords: ``
  },
  ['server_error']: {
    title: `500 Internal Server Error`,
    description: ``,
    keywords: ``
  },

  // After Login Pages
  ['dashboard']: {
    title: `Dashboard`,
    description: `Smart storage, Ship and storage, Storage and shipping, Storage facilities in New York, Storage facilities in Delaware, Storage and shipping services, Affordable storage, Cheapest storage, International storage and shipping, Storage and shipping services USA, Storage ez, Ez storage, Ez storage near me, Easy storage, Easy storage solutions, Easy storage near me, My storage near me, Self storage, Self storage near me, Self storage units near me, self storage facilities near me`,
    keywords: `Store 1 Small box To 100 Large boxes Storage with Free Insurance up to $250 and 6 Months Free Storage. Get Smart Storage Services And ship anytime, anywhere!`
  },
  ['my_shipment']: {
    title: `My Shipment`
  },
  ['my_storage']: {
    title: `My Storage`
  },
  ['order_processing']: {
    title: `Order Processing`
  },
  ['custom_documents']: {
    title: `Custom Documents`
  }
})
